
.projects-components {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    }
}

.project-cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}