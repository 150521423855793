.primaryLogo {
    font-family: 'Bree Serif', serif;
    font-size: 2rem;
    color: #D2A188;
    margin-left: 3rem;
    cursor: pointer;
}

.logoPoint {
    color: black;
}