.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__info {
        background-color: var(--peach);
        border-radius: 0.625rem;
        padding: 2rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: white;

        h2 {
            color: #FFF;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
    }

    a, .info {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }

    @media (min-width: 901px) {
       flex-direction: row;
       justify-content: center;
    }

}