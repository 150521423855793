.project-card{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;

    @media (min-width:901px) {
        max-width: 20%;
    }
}
.project-card__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project-card__image{
    max-width: 100%;
}
.project-card__image img {
    border-radius: 0.7rem;
    max-width: 100%;
}