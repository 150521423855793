//Grid breakpoints
//Define the minimun dimensions at which your layout will change,
//adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  sm: 0,
  md: 401px,
  lg: 901px,
  xl: 1201px,
  xxl: 1600px
);

.container {
  margin: 3.125rem;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 0 6.25rem;
  }
}

.container-margin-left {
  margin-left: 3.125rem;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 0 6.25rem;
  }
}

.container-margin-right {
  margin-right: 3.125rem;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 0 6.25rem;
  }
}