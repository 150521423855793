.navbar {
    height: 5rem;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--pink);
}

.navbar.active{
    background: rgba(255, 244, 237, 0.9);
}

.logo {
    height: 3rem;
    width: 4rem;
}

.desktopMenuItem {
    color: var(--dark-font);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 1.5rem;
}

.mobileMenuItem {
    color: var(--dark-font);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.desktopMenuItem:hover {
    color: var(--peach);
}

.mobileMenuItem:hover{
    color: var(--peach);
    margin: 0.6rem;
}