/*
* INTRO SECTION STYLES
*/

/* Navigation and Welcome Section */

#intro-home {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100vw;
    text-align: left;
    align-items: center;
}

.home {
    background-image: url('../../assets/homeIntroBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--pink);
}

.home__image {
    max-width: 100%;
    justify-content: center;
}

.home__image img {
    border-radius: 50%;
    max-width: 100%;
}

.home__content-ctas {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.welcome__paragraph {
    max-width: 82%;
}

.video-cta {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    display: none;

}

@media (min-width: 401px) {
    .home {
        &__image {
            max-width: 21.875rem;
        }
    }
}

@media (min-width:901px) {
    #intro-home {
        flex-direction: row;
    }

    .home {
        &__welcome {
            flex: 1;
            margin-right: 3.125rem;
            max-width: 56%;
            margin-top: 1rem;
        }

        &__image {
            flex: 1;
            max-width: 40%;
            margin-top: 1rem;
        }
    }
}

@media (min-width: 1000px) {
    .home__content-ctas {
        flex-direction: row;
    }

    .homeDecorationOne {
        float: right;
        position: relative;
        top: -50px;
    }

    .homeDecorationTwo {
        float: right;
        left: -250px;
        position: relative;
    }
}

/* About Section */
#about {
    padding: 3rem 0;
    background: white;

}

/* Projects Section */
#projects {
    background: #FFF9F5;
    padding: 3rem 0;
}

/* Experience Section */
#experience {
    padding: 3rem 0;
    background: white;
}

/* Reviews Section */
#reviews {
    padding: 3rem 0;
    background: #FFF9F5;

    .reviews {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    .reviews-intro{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (min-width:1175px) {
            max-width: 48%;
            height: 260px;
        }
    }
}

/* Experience Section */
#education {
    padding: 3rem 0;
    background: white;
}