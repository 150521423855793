.footer {
  &__card-cta {
    border-radius: 1rem;
    background-color: #D2A188;
    background-image: url('../../../assets/footerBg.svg');
    padding: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 901px) {
      flex-direction: row;
      gap: 0;
    }
  }
}

.card-title,
.card-description {
  color: white;
  text-align: left;
  margin-top: 1rem;

  @media (min-width: 901px) {
    margin: 0;
  }
}

.footer__nav,
.footer__copyrights {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 901px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;

  }
}

.footerMenu {
  display: flex;
  gap: 1rem;
}

hr {
  border: 0.3px solid #cccc;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.footer__copyrights {
  color: #424242;
  margin-bottom: 2rem;
}

.copyright__icons {
  display: flex;
  gap: 1rem;
}

.copyright__icons .icon:hover {
  color: white;
  background-color: #D2A188;
  border-radius: 50%;
  padding: 0.15rem;
}