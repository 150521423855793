.review-owner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.reccomendation-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.reccomendation-card__image {
    max-width: 100%;
}

.reccomendation-card__image img {
    border-radius: 50%;
    max-width: 3rem;
}


.reccomendation {

    @media (min-width:1175px) {
        max-width: 48%;
        height: 260px;
    }
}