form {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background-color: white;
    align-items: center;

    input,
    textarea {
        font-family: Poppins;
        outline: none;
        margin: 0;
        width: 100%;
        font-size: 0.875rem;
        padding: 1rem;
        margin-bottom: 2rem;
        border: 0.063rem solid #e1e1e1;
    }

    textarea {
        height: 6.25rem;
        font-family: Poppins;
    }

    .submit-button {
        outline: none;
        width: 5rem;
        background-color: var(--peach);
        color: white;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 0.5rem;

        &:hover {
            background-color: var(--pink);
            color: var(--dark-grey)
        }
    }
}

@media (min-width: 901px) {
    form {
        width: calc(80vw / 2);
    }
}