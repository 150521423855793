.section-title {
    display: flex;
}

.title {
    text-align: left;
}

.title-decoration {
    position: relative;
    bottom: 18px;
}