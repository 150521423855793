.base-button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 2.5625rem;
    border: 0;
    cursor: pointer;
    line-height: 1;
    height: 2.5rem;
}

.button--primary {
    color: white;
    background: #D2A188;
}

.button--secondary {
    color: #D2A188;
    background-color: white;
}

.button--small {
    font-size: 12px;
    padding: 10px 16px;
}

.button--medium {
    font-size: 14px;
    padding: 11px 20px;
}

.button--large {
    font-size: 1rem;
    padding: 12px 24px;
}