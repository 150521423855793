.timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width:901px) {
        flex-direction: row;
    }

    &__description{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}