.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    @media (min-width: 901px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.about__image {
    max-width: 100%;
    justify-content: center;

    @media (min-width: 401px) {
        max-width: 21.875rem;
    }

    @media (min-width:901px) {
        max-width: 50%;
        margin-top: 1rem;
    }
}

.about__image img {
    border-radius: 50%;
    max-width: 100%;
}

.about__paragraph {
    color: #6C6C6C;
}

.about-ctas {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    align-items: center;
}

.icon:hover {
    color: white;
    background-color: #D2A188;
    border-radius: 50%;
    padding: 0.15rem;
}

.skill {
    border-radius: 0.375rem;
    background: #F4F1EF;
    text-align: center;
    margin: 0 0.5rem;
    padding: 1rem;
}

.about__skills{
    margin: 3rem 0 0;
}