.header {
    color: #303535;
    text-align: center;
    font-family: Poppins;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 4rem;

}

.header p::after {
    display: flex;
    content: '';
    width: 8rem;
    height: 0.25rem;
    background: #D2A188;
    position: absolute;
    bottom: 0;
    left: 37rem;
    border-radius: 1rem;
}



.header p {
    position: relative;
}